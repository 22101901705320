import React from 'react';
import './Robozinho.css';
import robo from './robot.png'



const robozinho = (props) => {
    
    return (
        <div>
            <div className="falaRobo">
                <p className="palavrasRobo">Hi!</p> 
                <p className="palavrasRobo">If you have questions about Valkokettu or chatbots I am here to help you.</p>
            </div>    
            <div className="robozinho">  
                
                {props.roboFechado ?
                    
                        
                        <div className="roboIframe">
                            <img alt="logoRobo" 
                                src={robo}
                                style={{height:60, width:60}}
                                onClick={() => props.onClick()}></img>
                        </div>
                    
                    
                :
                    
                    <iframe className="roboIframeAberto"
                            src="https://master.d1g8nlqc5h7s5e.amplifyapp.com/"
                            title="Chatbot"
                    />
                }        
            
            </div>
        </div>
    )
}

export default robozinho;

