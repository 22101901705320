import React from 'react';
import './News.css';

const articleTeaser = (props) => {
  
    
    let nomeClasse = 'botaoTeaser';

    if (props.selected) {
        nomeClasse = 'botaoTeaser botaoTeaserSelected';
    }
  
  return (
      <article className={nomeClasse}
               onClick={() => props.changeArticle(props.index)}>
          <div className='botaoTeaserConteudo'>
              <h5>{props.title}</h5>
              <div>{props.teaser}</div>
              <h6>{props.signature}</h6>
          </div>
      </article>
      
  )
}

export default articleTeaser;