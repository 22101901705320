import React from 'react';
import './ImageBar.css';

const imageBar = props => {
    let nomeClasse = 'hello_image';
    
    switch (props.tamanho) {
        case 'small' :
            nomeClasse += ' small_image';
            break; 
        case 'medium':
            nomeClasse += ' medium_image'; 
            break;
        default:
            nomeClasse += '';
    }
    
    return (
        <div>
            <div className={nomeClasse} >
                <div className="nuvem">
                        <div className="titulo">
                                <h1 className = "tituloh1">{props.title}</h1>
                                <h2 className = "tituloh2">{props.subtitle}</h2>
                        </div>
                </div>
            </div>
            
       </div>


    );
}

/*
<h1>IMPROVING THE WORLD THROUGH TECHNOLOGY</h1>
                                <h2>Thrusting our clients into the future</h2>
                                */
 export default imageBar;