import React from 'react';
import Leader from './Leader';
import './ListLeaders.css';



const listlistLeaders = ({cardContents}) => {
    
    return (
    <div className="leaderlist">
       
        {cardContents.map((cardContent,i) => {
            
            return (
                    <Leader key = {i}
                          numero={cardContents[i].foto} 
                          alt ={cardContents[i].alt}
                          title = {cardContents[i].title}
                          cargo = {cardContents[i].cargo}
                          geografia = {cardContents[i].geografia}
                          description = {cardContents[i].description} 
                    />) 
            })}
             
    </div>
   );

}

export default listlistLeaders;