import icon1 from './Images/trusted_approach.png';
import icon2 from './Images/digital.png';
import icon3 from './Images/idea_to_deploy.png';
import icon4 from './Images/technology.png';
import icon5 from './Images/it_strategy.png';
import icon6 from './Images/dataScience.png';
import icon7 from './Images/business assessment.png';
import icon8 from './Images/project_management.png';
import icon9 from './Images/go to cloud.png';
import icon10 from './Images/system dev.png';
/*import icon10 from './gamification.png';*/
import icon11 from './Images/ifam.png';
import icon12 from './Images/readiness.png';
import icon13 from './Images/business_continuity.png';



export const icons = [icon1, icon2, icon3, icon4, icon5, icon6,
                      icon7, icon8, icon9, icon10, icon11, icon12, icon13] 
    