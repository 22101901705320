import React from 'react';
import ArticleTeaser from './ArticleTeaser';
import './News.css';
import SignNews from './SignNews';


const ListOfNews = (props) => {
    
    let artigos = props.reverseList;
    
    return (
        

        <div className="blogTeaser">
            <SignNews></SignNews>
            
            {artigos.map((artigo, i) => {
                return ( 
                    <ArticleTeaser 
                            key={artigos[i].index} 
                            index={artigos[i].index}  
                            item={artigos[i].image -1}
                            title={artigos[i].title}
                            teaser={artigos[i].teaser}
                            author={artigos[i].signature}
                            selected={artigos[i].index === props.selected ? true : false}
                            changeArticle={props.onChangeArticle}
                            >
                    </ArticleTeaser>)
            })}

        </div>

        
        
    )

    
    
}

export default ListOfNews; 