import React from 'react';
import MenuFiller from '../ImageBar/MenuFiller';
import ImageBar from '../ImageBar/ImageBar';
import {mainText} from './ServicesData';
import Servico from './Servico';
import MoveTo from '../MoveTo/MoveTo';

const services = props => {
    let title = mainText[0].title;
    let subtitle = mainText[0].subtitle;
    let txtAbout = mainText[0].description;
    let subject = mainText[0].subject;
    
    return (
        <section >
            <MenuFiller></MenuFiller>
            <ImageBar tamanho='medium'
                     title={title}
                     subtitle={subtitle}></ImageBar>
            <div className="descricaoPrincipal">
                <h1>{subject}</h1>
                <p>{txtAbout}</p>
            </div>
                                           
            <Servico listaServicos = {props.listaServicos}
                       readMoreClickHandler={props.readMoreClickHandler}
                       numero={props.numero}></Servico>
            <MoveTo for='Who we are ' 
                    forRoute='home'></MoveTo>           
        </section>
        
       
    );
}

export default services; 