import React from 'react';
import ImageBar from '../ImageBar/ImageBar';
import MenuFiller from '../ImageBar/MenuFiller';
import ListCards from '../Card/ListCards';
import '../../App.css';
import {coreValue, mainText} from './CoreValuesData';
import MoveTo from '../MoveTo/MoveTo';

const coreValues = props => {
    let title = mainText[0].title; 
    let subtitle = mainText[0].subtitle;
    let txtAbout = mainText[0].description;
    let subject = mainText[0].subject;
    return (
        
            
            <section >
                <MenuFiller></MenuFiller>
                <ImageBar 
                     title={title}
                     subtitle={subtitle}></ImageBar>
                <div  className="descricaoPrincipal">
                    <h1>{subject}</h1>
                    <p>{txtAbout}</p>
                </div>
                <ListCards cardContents={coreValue}></ListCards>
                <MoveTo for='What can we do for you ' 
                        forRoute='service'></MoveTo>
            </section>
        
       
    );
}

export default coreValues; 