import React, { Component } from 'react'
import RobotSampleVideo from './RobotSampleVideo'


class listOfVideos extends Component {
    constructor () {
        super()
        this.state = {videos: []}
    }
    
    componentDidMount(){
        this.buscaVideos()
        
    }
    
    
    buscaVideos = async () => {
        await fetch('https://gu1p4wnv49.execute-api.us-east-1.amazonaws.com/prod', 
                    { method: 'post',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        videos: "VKTSite",
                        version: "1"
                    })
                    }
                )
            .then(response => response.json())
            .then(resp => {
                
                this.setState({videos: resp.dados})
                
            })
            .catch(error => {
                console.log(error)
                
            });
        
    };

    
    render() {
        
        let listaVideos = this.state.videos
        return (
            <div className="listaVideos">
                
                {listaVideos.map((item, i) => {
                    return (
                        <RobotSampleVideo key={i}
                                          titulo={item.texto}
                                          videoUrl={item.url}
                                          nome={item.video}>
                        </RobotSampleVideo>
                    )
                })}
                
            </div>
    
        )
    }
    
}

export default listOfVideos

