import React from 'react';
import './MessageBox.css';

const messageBox = ({tipo, message}) => {
    let classType;

    switch(tipo) {
    case 'aviso':
        classType = 'aviso';
        break;  
    case 'erro': 
        classType = 'erro';
        break;
    default:
        classType = 'nada';
    }  
    
    return (
        <div className={classType}>
            <div>{message}</div>
        </div>
    );

}

export default messageBox;