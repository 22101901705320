import React from "react";
import art2pic1 from './itSpendingGraf1.png';
import art2pic2 from './itSpendingGraf2.png';

export const listOfArticles = [
    {
        language: 'ing',
        index: 20200610,
        image:1,
        alt: 'Trakai Castle',
        title:'Why projects fail?',
        subtitle: 'A different perspective',
        teaser: <><p>More often than not, a project finishes on time and on budget, the team celebrates its accomplishments, but the sponsors are not satisfied. How is it possible? What happened along the way? What led the project to fail to deliver its impact on the business? On this article I tackle some of the more complex reasons projects fail and give some advice to avoid them.</p></>,
        artigo: <><p>Projects appeared when the human race started to work together to achieve a specific goal. The objective could be to kill a big animal to provide food for a community, build shelter from unpredictable weather or fight a common enemy. As society evolved, the construction of bridges, churches, roads, machines and almost everything is based on projects.</p>
                  <p>At the beginning it was not called a project, but the successful ones were (and still are) always based on teamwork, knowledge, experience, leadership and method.</p>
                  <p>On this article I want to address two reasons why projects fail. Both are difficult to anticipate or understand. I am not going to talk about the very basic mistakes of project management, such as under budget, under staffing, bad scope management, lack of knowledge or bad leadership.</p>
                  <p>Here, I will assume that those aspects are taken care of, that is, there is a good and knowledgeable team, everybody is motivated, the budget is minimally right for the objectives and some kind of project management is performed.</p>
                  <p>I want to focus on the <strong>“soft”</strong> reasons. Those that act like water on stone. Nobody notices them, but they slowly add up over time and eventually lead to failure. In addition, sometimes even though the project objectives are met, they are late and costs were high. I also consider that those are failed projects.</p>
                  <h4>Soft Reason 1 – Project Objective X Business Issue</h4>
                  <p>If the project team doesn’t understand how the project impacts the business strategy, chances are the project will not have a good outcome.</p>
                  <p>Of course, most projects start with a nice kickoff meeting or event where team members and stakeholders go over the project objectives, high level milestones and project organization. Unfortunately, many project kickoffs fail to discuss the Business Issue - WHY the project exists. What is the business issue that the project will tackle?</p>
                  <p>There is a difference between Project Objective and Project Reason. The objective of the project is most of the times very clear. Some examples are </p>
                  <ul><li>launch a new product,</li><li>automate a process,</li><li>achieve compliance to a set of regulations,</li>
                      <li>integrate information or systems,</li><li>improve customer loyalty and so on.</li></ul> 
                  <p>The project reason has to be business related. Be it a new market opportunity, a competitive threat, a cash flow adjustment, a sustainability advancement, a revenue growth or many others.</p>    
                  <p>Sometimes the project reason is declared on the kickoff in terms of: Our project objective is <strong>X</strong> so that our company can do <strong>Y</strong>. But this is not enough. What happens after the kickoff?</p>
                  <p>Normally, during the first month the team is motivated, people remember the kickoff, initial discussions and the general guidelines. The work flows easily. However, as times goes by, the Business Issue fades away and the project team is only focused on its objectives. After all, their responsibility is to deliver the project!</p>
                  <p>The reason for the project has to be live in each team member’s mind. It has to guide all the project’s decisions. The project cannot exist only to fulfill its objective. It exists to solve a business issue.</p>
                  <p>The best way to make sure both the business issue and the project objective are addressed is by remembering it in every status meeting and whenever the team makes important decisions. For example, every time the team reviews a new requirement the question of how this requirement addresses the business issue has to be answered. It has work as mantra.</p> 
                  <h4>Soft Reason 2 – Poor Communication</h4>
                  <p>Good communication and engagement of the project team, sponsors and stakeholders is key to every successful project.</p> 
                  <p>Once again, all is good at kickoff and initial weeks. Time makes some basic routines or ceremonies to fade away. Slowly, the course of the project is changed.</p>
                  <p>Those with nearly or full-time allocation to the project know what is going on. They understand the decisions and tradeoffs of the project and are all eager to deliver a successful project. The devil lies in the small changes of course, the small decisions that slowly add up to deviate the project from its original objective.</p>
                  <p>It is difficult to maintain executive support at the same level throughout the entire project. It is natural that some of the members of the project board have many priorities. Sometimes they miss a few status updates and that is how they start to lose the grip of the project.</p>
                  <p>I have seen projects celebrate their accomplishments at the same time that the sponsors realize that it is not solving 100% of the original business issue. Some requirements have left the scope, or some project decision undermined the main objective, and at the end, it is too late or too expensive to fix it.</p>
                  <p>The sooner small deviations are corrected the less they will cost. It is like setting off for a long journey at sea from Europe to America with a 1% error in direction. After 2 months of navigation you find out that you arrived in Argentina instead of Brazil. Even though it is a nice place, it is not the original destination.</p>
                  <p>Managing the expectation of the sponsors about the project is one of the most important tasks of the project management team. This can only be achieved with active communication. Weekly Status Meetings are not enough. There has to be constant touch points of the sponsors with the project. Information has to flow, and this is mainly up to the management team.</p>
                  <p>I am not saying that the course cannot change. Maybe it is the best decision. Business always change and the project has to change accordingly. It happens all the time. Nevertheless, everyone involved has to be aware of the changes and understand its implications. Arriving in Argentina cannot be a surprise!</p>
                  <p>Do you have an important project going on and want to know how healthy it really is? Are you sure that it is going to solve your original business issue? Is it still on the right track?</p>
                  <p>At Valkokettu we have lived through hundreds of projects. Our experience can help you spot minor project issues that can hinder its results. Get in contact and we can help you solve your business issue.</p>
                </>,
        signature: 'Fábio Glaser',
        position: 'Co-founder and CEO',
        email: 'fabioglaser@valkokettu.fi'
        
    },
    {
        language: 'ing',
        index: 20200812,
        image:2,
        alt: 'Xadrez',
        title:'Information Technology Budget',
        subtitle: 'How much is expensive?',
        teaser: <><p>How much is too much? How can executives be sure that the money allocated to IT is well spent? How much value a company can expect from its IT area? Is the IT Strategy being supported by the IT budget?<br></br>Those are all valid questions when the issue at stake is cost and investment in technology. Valkokettu can help your organization create a unique IT Strategy based on budget, architecture and processes. </p></>,
        artigo: <><p>An IT Strategy is a plan to create an Information Technology capability that delivers sustainable value for the company’s shareholders. An important part of every IT Strategy deals with the budget also known as IT Spending.</p>
                  <p>IT Spending is always a difficult subject to IT Executives. IT is a major enabler for many businesses, it is a framework on which companies rely to sell services, support customers and even handle everyday tasks. Nevertheless, looking from the business budget point of view, IT’s share on the companies’ budget always stands out as an area of opportunity to cut ‘costs’.</p>
                  <p>The real question is not how much IT costs. A more interesting perspective to this issue is how much value IT brings to the business. Furthermore, it is important to understand on the long run, how is the IT budget being spent? Is it only cost to the company? What kind of behavior does it have over the past years? How will it be in 3 or 5 years? Once those questions have answers, it is possible to create indicators related to the business that will enable a company to benchmark its IT Spending against market peers or industries averages.</p>
                  <p>Valkokettu’s approach to those issues leverages on the Run – Grow – Transform (RGT) model first developed by Gartner, to help our customers understand and create an IT Strategy. In its essence the RGT model labels every IT expense of a company into “Run”, “Grow” or “Transform”.</p>
                  <h4>Run - Grow - Transform Model</h4>
                  <img className="articlePicture" src={art2pic1} alt="grafico1"></img>
                  <p>The <strong>“Run”</strong> type expenses are related to everyday operation of the company measuring how much it costs to operate and maintain. It represents how much it costs to “keep the lights on”. Some examples of “Run” expenses are costs in infrastructure, operations, customer support, application maintenance, security and regulatory obligations.</p>
                  <p><strong>“Grow”</strong> type resources are spent in activities focused on developing and enhancing the IT systems needed to support business growth. As IT systems are used, more hardware is needed, databases get bigger and more capacity is required. It is also known as organic growth. “Grow” money is also used to improve business processes by automation for example.</p>
                  <p><strong>“Transform”</strong> related expenses are used to enter new markets, create new products, recreate system architectures, explore new business models or support innovation projects. Those are normally high complexity and high-risk projects with good ROI (Return on Investment) potential. Transformation projects have huge impact on revenue and costs.</p>
                  <p>Once a companies’ budget is categorized in the RGT model, it is possible to analyze and compare past years with future plans. Several insights can come from such exercises. The graph below shows an example:</p>
                  <img className="articlePicture" src={art2pic2} alt="grafico2"></img>
                  <p>This hypothetical example shows a company with increasing operational costs – “Run”. It is a clear case where the IT Strategy has to be redirected because in a few years the cost to operate will consume the entire budget dedicated to IT, which in turn will stall the company’s growth becoming only a burden. In this case we can say that the IT area is costing a lot of money to the company.</p>
                  <p>With information at hand, IT executives can create metrics such as business revenue over operational cost, revenue growth over transformation investment and so on. This metrics can then be used to compare different companies and different budgets.</p>
                  <p>If you don’t know where you are, then you cannot know where you are going. The Run – Grow – Transform model is just one tool that can be used to fine tune an IT Strategy. Based on years of experience in IT Strategy and IT Spending, Valkokettu can help companies of different industries and all sizes to optimize their IT Strategy and guarantee delivery of maximum value to its shareholder at the same time that provides impeccable customer service.</p>
                  <p>What kind of challenges are you dealing right now? Do you have a bullet proof IT Strategy? Get in contact and we can help you design and execute the best Strategy for your business.</p>
                </>,
        signature: 'Fábio Glaser',
        position: 'Co-founder and CEO',
        email:'fabioglaser@valkokettu.fi'
        
    }
];

export const mainText = [
    {
        language: 'ing',
        id: 0,
        subject: 'Articles',
        title:'ARTICLES, NEWS, PLANS ...',
        subtitle: 'and what else',
        description: <></>
    }
]