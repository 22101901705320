import React from "react";
import Question from './Question';
import Conteudo from './Conteudo';


const determinaClasse = (question) => {
  switch(question) {
      case 'what': 
          return 'whatColor'
      case 'why' :
          return 'whyColor'
      case 'where' :
          return 'whereColor'
      case 'how':
          return 'howColor'
      default:
          return 'erro'

  }
}

const quadro = (props) => {
    
  

    return (
      <div className={"quadro " + determinaClasse(props.question)}>
          <Question question={props.question}></Question>
          <Conteudo assunto={props.question}></Conteudo>
      </div>
  )
}

export default quadro