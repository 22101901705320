import React from 'react';
import './Leader.css';
import {photo} from './LeadershipData';

const leader = ({numero, alt, title, cargo, geografia, description}) => {
    
    let picture = photo[String(numero)-1];
    
    return(
        <article className="socio">
            
                <img src={picture} alt={alt}></img>
                <h2>{title}</h2>
                <h4>{cargo}</h4>
                <h3>{geografia}</h3>
                <p>{description}</p>
            
        </article>
    )
}

export default leader;

