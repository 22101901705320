import React from 'react';
import './Servico.css';
import ListaServico from './ListaServico';
import DescricaoServico from './DescricaoServico';

const servico = (props) => {
    
    return (
        <div className="servico">
            <div className="botoes">
            <ListaServico cardContents={props.listaServicos} 
                          numero = {props.numero}
                          readMoreClickHandler={props.readMoreClickHandler} ></ListaServico>
            </div>
            <div className="conteudo">
                <DescricaoServico cardContents={props.listaServicos}
                                  numero = {props.numero}></DescricaoServico>
            </div>
        </div>

   )

}

export default servico;