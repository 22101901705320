import React from 'react';
import { Link } from 'react-router-dom';
import './MoveTo.css';

const moveTo = (props) => {
    return (
        <section >
            <div className="moveTo">
               <p>
                  <Link to={props.forRoute}>>{props.for}</Link>
               </p>
               
            </div>
           {/* <div>{props.back}</div> */}
        </section>


    )
}

export default moveTo;