import React from 'react';
import './Leadership.css';
import MenuFiller from '../ImageBar/MenuFiller';
import ImageBar from '../ImageBar/ImageBar';
import ListLeaders from './ListLeaders';
import {leader, mainText} from './LeadershipData';

const leadership = props => {
    let title = mainText[0].title;
    let txtAbout = mainText[0].description;
    let subtitle = mainText[0].subtitle;
    let subject = mainText[0].subject;

    return (
        <section>
             <MenuFiller></MenuFiller>
             <ImageBar tamanho='medium'
                     title={title}
                     subtitle={subtitle}></ImageBar>
            <div className="descricaoPrincipal">
                <h1>{subject}</h1>
                <p>{txtAbout}</p>
            </div>
            <ListLeaders cardContents={leader}></ListLeaders>
        </section>
        
    )

    
}

export default leadership;