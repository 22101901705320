import React from 'react';
import '../Blog/Blog.css';
import {imagens} from '../../blogImages';


const newsArticle = (props) => {
    
    let emailTo = 'mailto:' + props.email;

    return (
        <article className="blog">
                <h1>{props.title}</h1>  
                <h3>{props.subtitle}</h3>   
                <div className="articleImage">
                    <img className="articlePicture" alt={props.alt} src={imagens[props.item]}/>
                </div>
                <div>{props.body}</div>
                <div className="articleSignature">
                    <p className="writer">{props.author}</p>
                    <p className="writerPosition">{props.position}</p>
                    <p className="writerEmail"><a href={emailTo}>{props.email}</a></p>           
                </div>
        </article>
        
    )

    
    
}

export default newsArticle; 