import React, {createRef, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'; 
import MenuFiller from '../ImageBar/MenuFiller';
import ImageBar from '../ImageBar/ImageBar';
import { mainText } from '../Blog/BlogData';
import './News.css';
import ListOfNews from './ListOfNews';
import NewsArticle from './NewsArticle';


function News(props) {
    
    let history = useHistory();

    let id = props.index;
    
    let title = mainText[0].title;
    let subtitle = mainText[0].subtitle;
    
    let reverseList = props.reverseList;
    let lastNews = useArticle(reverseList[0].index);
    const inicioArtigo = createRef(); 

    let item = {};
    
        if (id !== null) 
        {
           reverseList.forEach(artigo => {
                if (artigo.index.toString() === id) {
                    item = artigo;
                }
            }); 
        }
        else {
            item = reverseList[0];
        }
          
     /* se entrou na pagina de News sem escolha do artigo -- pega o ultimo */
     useEffect (() => {
         
                 
         if (id === null) {
             /* entrou na pagina de News sem escolha do artigo -- pega o ultimo */   
             history.replace('/news?id='+lastNews.index);

            }
            else {
                /* trocou de artigo -> atualiza a url com o novo artigo */
                if (lastNews.index.toString() !== id) {
                    history.push('/news?id='+lastNews.index); 
                    
                    inicioArtigo.current.scrollIntoView('start', 'smooth'); 
                }  
            } 
        
    })
    
    
    
    return (
        <section>
            <div ref={inicioArtigo}></div>
            <MenuFiller></MenuFiller>
            <ImageBar  tamanho='small'
                        title= {title}
                        subtitle={subtitle}></ImageBar>

            <div  className="news">
               <div className="artigo" >
                   <NewsArticle key={item.index} 
                               item={item.image -1}
                               title={item.title}
                               subtitle={item.subtitle}
                               body={item.artigo}
                               author={item.signature}
                               position={item.position}
                               email={item.email}
                   
                   ></NewsArticle>
               </div>
               <div className="listaArtigos">
                   <h2>Other news ...</h2>

                   <ListOfNews reverseList={props.reverseList}
                               selected={item.index} 
                               onChangeArticle={lastNews.changeArticle}></ListOfNews>
               </div> 
            </div>

        </section>
        
    )

    
    
}

export default News; 

function useArticle(lastNewsIndex) {
    const [articleIndex, setArticleIndex] = useState(lastNewsIndex);

    function changeArticle(index) {
        console.log('trocou artigo');
        setArticleIndex(index);
    }

    return {
        index: articleIndex,
        changeArticle
    }
}
   
