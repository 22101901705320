export const itensMenu = [
    { language: 'ing',
      item: 'Home',
      route: 'home'
    },
    { language: 'ing',
      item: 'Services',
      route: 'service'
    },
    { language: 'ing',
      item: 'Leadership',
      route: 'leadership'
    },
    {
      language: 'ing',
      item: 'Partnership',
      route: 'partnership'
    }, 
    {
      language: 'ing',
      item: 'Chatbots',
      route: 'robotti'
    }, 
    {
      language: 'ing',
      item: 'News',
      route: 'news'
    }, 
    { language: 'ing',
      item: 'Contact',
      route: 'contact'
    },

];