import React from 'react';
import { Link } from 'react-router-dom';
import './SideDrawer.css';
import {itensMenu} from '../Navigation/NavigationData';


const sideDrawer = props => {
    let drawerClass = 'side-drawer';
    if (props.show) {
        drawerClass = 'side-drawer open'
    }

   
   


    return (
        <nav className={drawerClass}>
            <ul>
                {itensMenu.map((menuItem, i) =>  {
                    return (
                            <li key={i} >
                               <Link to={menuItem.route}
                                     onClick={() => props.menuOnClick()}>{menuItem.item}</Link>
                               
                            </li>)
                })}
            
            </ul>
        </nav>

    );

    
}

export default sideDrawer;