import React  from "react";

export const partner = [
    {
      partner: 'Objective',
      description:'Software Development - Agile Methodology',
      link: 'https://objective.com.br',
      site:'www.objective.com.br'  
    },

    {
      partner: 'Scopphu',
      description:'Agile Transition Company - trainning, coaching and talent management',
      link:'https://scopphu.com',
      site: 'www.scopphu.com'
    },
    {
      partner: 'Division 5',
      description: 'High performance software development agency that helps startups and corporates build amazing web and mobile products. As dedicated teams or for project work. Fast, reliable and affordable.',
      link:'https://division5.co' ,
      site:'www.division5.co' 
    }
    
];

export const mainText = [
  {
      language: 'ing',
      subject: 'Partnership',
      title:'WHEN ONE PLUS ONE EQUALS MORE THAN TWO …',
      subtitle: 'Strategic partners work side by side to deliver projects',
      description: <>Successful partnerships stem from close collaboration, synergistic skills and complementary outlooks. Even though Valkokettu’s extensive experience covers many disciplines within the IT landscape, it is virtually impossible to excel in every area. Also, sometimes there is just too much work to be done in a given timeframe to deliver value to our customers.
                   <br></br><strong>Valkokettu has chosen a handful of trustful partners</strong> that share our visions and are willing to work alongside us in our projects. We have selected these few companies based on our mutual understanding, flexibility of interaction and the synergy that we can achieve by working together. </>
  }
]

