export const extensionActualDomain = (base) => {
    let url = window.location.href;
    
    let inicio = url.indexOf(base);
    if (inicio === -1) {
        return 'com';
    }
    
    inicio += base.length;
    let termino = url.indexOf('/', inicio);


    return (url.substring(inicio, termino))
}



export const handleFetchError = (response) => {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}