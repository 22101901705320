import React from 'react';

const what = [
    { id: 1,
      language: 'en',
      message: <>Atena is a chatbot, powered by rules and AI, which simulates a real interaction with users. In other words, Atena Chatbot is a service that can have a conversation just like a real person.</>
     } ,
     { id: 2,
        language: 'en',
        message: <>Atena is based on Machine Learning and Natural Language Processing  to gather conversational cadences that allows her to copy human conversations and react to written or spoken requests to deliver a service. Since it relies on Artificial Intelligence, it comprehends human language, not computer commands. Therefore, the more conversation Atena has with users, the more intelligent it becomes.</>
     }, 
]

const why = [
    {   id: 100,
        language: 'en',
        message: <>The main purpose of  Atena is to support businesses in the relationship with its current and future customers or even to its employees. It does it by simplifying the interaction between humans and computers.</>
    },
    {   id: 101,
        language: 'en',
        message: <>Atena is always available, can scale to handle as many simultaneous chats needed at a time, it is very cost effective when compared to the human infrastructure necessary to handle customer inquiries and is very effective at recurring jobs.</>
    },
    {   id: 102,
        language: 'en',
        message: <>Some of the main areas where Atena can have a significant impact for a business are: Provide Business Information, Answer Questions, Check Customer Information, Find Recommendations, Track Shipping, Collect Customer Feedback, Generate Leads, Build Email Lists, Provide Quotes or Estimates and  Make Reservations. The applications are limitless.</>
    }
]

const where = [
    {   id: 200,
        language: 'en',
        message: <>Atena is 100% cloud based, meaning that it can be integrated easily with any type of customer service platform. It will also grow as much as needed to handle whatever volume of interactions is necessary.
        </>
    },
    {   id: 201,
        language: 'en',
        message: <>Nowadays, every business has a Website and Social Media presence. Atena can be triggered in almost any platform available. Some common places to deploy Atena’s interface are: Web Site, Facebook, Whatsapp, Slack, Mobile App and even some voice interfaces such as Alexa</>
    },
    {   id: 202,
        language: 'en',
        message: <>The most important aspect of the Atena project is how much interaction it will have with backend information systems. Secure and reliable integrations require careful planning, quality development and extensive testing before it is safe to launch into the world.</>
    }   

]

const how = [
    { id: 300,
      language: 'en',
      message: <>It is very easy to build and deploy a stupid Chatbot that will either frustrate your customers or even worse provide wrong and meaningless information. That is not the case for Atena.</>
     } ,
     { id: 301,
        language: 'en',
        message: <>We developed an extensive technical framework to accelerate the deployment of Atena, but the most important part of the project is the design phase.</>
     },
     { id: 302,
        language: 'en',
        message: <>By applying our unique methodology, we can gather your business requirements and goals so that at the end of the project a powerful engagement tool is deployed. One that will really bring new insights for your business and solve real business issues.</>
     },
     { id: 303,
        language: 'en',
        message: <>At the design phase, all integration points will also be reviewed to guarantee that there will be no wasted efforts in the project.</>
     }

]

const mainText = [
    {
        language: 'ing',
        subject: 'Chatbot',
        title:'ATENA',
        subtitle: 'Valkokettu’s approach to Chatbots and Customer Engagement',
        description: <>Customer engagement and loyalty are the most precious assets that any business can have. Rapidly solving queries and problems of customers or employees is critical in today’s competitive market. Valkokettu developed an innovative and flexible tool to help companies manage their relationship with customers and employees.<br></br>
                       Atena is our AI powered Chatbot that will help you boost your business. Multilingual by design and product of a thorough development process, Atena is ready to take you to the next level of Customer Service.<br></br>
                       Atena is not a ‘one size fits all’ solution. For each of our projects, we deliver many years of customer relationship experience. Your version of Atena Chatbot will be tailored to your needs to guarantee maximum return of your investment.
                     </>
    }
]

export {what,
        why,
        where,
        how,
        mainText }