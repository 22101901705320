import React from 'react';
import MessageBox from '../MessageBox/MessageBox';
import './News.css';

const autoMessage = [
    {numero: 0,
     mensagem: <p>Your email was register successfully!</p>,
    },
    
    {numero: 1, 
     mensagem: <p>Your solicitation could not be sent, please try later.</p>   
    }

]

class signNews extends React.Component {
    constructor() {
        super();
        this.state = {
            sign: true,
            email: '',
            tipoMsgEnvio: 'nada',
            msgEnvio: '',
        }
    }

    onEmailChange = (event) => {
        this.setState({email: event.target.value,
                       tipoMsgEnvio: 'nada'});
    }   

    onSignChange = (event) => {
        let estado = this.state.sign;
        this.setState({sign: !estado});
    }

    onSubmit = () => {
        let erroValidacao = false;
        let msgErro;

        /*troca botão por msg de enviando ... */
        this.setState({tipoMsgEnvio: 'aviso',
                           msgEnvio: 'Sending...'}) ;

        fetch('https://teezb46mo0.execute-api.us-east-1.amazonaws.com/beta', 
            { method: 'post',
             headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                        email: this.state.email,
                        dados: {}
                })
            }
        )
        .then((response) => {
            if (!response.ok) {
                switch(response.status) {
                    case 422 :  {
                                    erroValidacao = true;
                                    break;
                                }
                    default:
                        throw new Error('Erro no envio de email');
                }
            }
            return response;})    
        .then(response => response.json())
        .then(res => {
                if (res) {
                    if ((res.statusCode === 422) || (erroValidacao === true)) {
                        msgErro = <p>{res.body}</p>;              
                        
                        this.setState({tipoMsgEnvio: 'erro',
                                        msgEnvio: msgErro})
                    }   
                
                    else { 
                        console.log("sucesso")
                        this.setState({tipoMsgEnvio:'aviso',
                                        msgEnvio: autoMessage[0].mensagem})   
                    }  
                }
            })
        .catch(err => {
            this.setState({tipoMsgEnvio: 'erro',
                        msgEnvio: autoMessage[1].mensagem})   
            
        });                
    } 

    
    render() {
        let mostraEmail = this.state.sign ? "mostra" : "esconde"

        return(
            <div className="signBox">
                <div>
                    <input type="checkbox" name="signNews" id="signNews"  
                            checked={this.state.sign} onChange={this.onSignChange}></input>
                    <label htmlFor="signNews">I want to be informed about Valkokettu's news</label> 
                </div>
                <div className={mostraEmail}>
                    <div className="emailInput">
                        <label className="labelEmail" htmlFor="signEmail">Email</label>
                        <input className="txtbox" type="email" name="signEmail" id="signEmail"
                                onChange={this.onEmailChange}></input>
                    </div>
                    
                    { this.state.tipoMsgEnvio === 'nada' ? 
                        <button className="btnSign" type="submit"
                                    onClick={this.onSubmit}>Subscribe</button>
                             
                    :
                       <MessageBox tipo={this.state.tipoMsgEnvio} 
                                   message={this.state.msgEnvio}></MessageBox> 
                    }
                </div>
            </div>
            
        )

    }   
}

export default signNews