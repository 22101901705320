import React from 'react';
import "./Robotti.css"

const question = (props) => {
    
    return (
        <div className="questionBox">
            <p className="question">{props.question}</p>
        </div>
    )
}

export default question