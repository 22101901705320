import React from 'react';
import logomarca from './blackLogo20ptp.png';
import './Footer.css';

const footer = props => {
    return (
        <footer className="fundo"> 
            <div className="objetos">
                <a className="logoFooter" href="/"><img  alt="logo"  src={logomarca}  /></a>
                <article className="escritorio">
                    <h4>Finland</h4>
                    <div className="contato"><a href="mailto:fabioglaser@valkokettu.fi">Fabio Glaser +358 404822033</a></div>
                    <div className="contato"><a href="mailto:reginaglaser@valkokettu.fi">Regina Glaser +358 403547553</a></div>
                </article>

                <article className="escritorio">
                    <h4>Portugal</h4>
                    <div className="contato"><a href="mailto:antoniocunha@valkokettu.fi">Antonio Ribeiro da Cunha +351 933265762</a></div>
                </article>

                <div className="midiasSociais">
                    {<a href="https://www.linkedin.com/company/valkokettu" className="linkedin">
                        <svg className="iconeMidiaSocial" x="0px" y="0px" viewBox="0 0 48 48" >
                            <linearGradient gradientUnits="userSpaceOnUse" x1="23.9995"
                                y1="0" x2="23.9995" y2="48.0005" >
                                <stop offset="0" ></stop>
                                <stop offset="1" ></stop>
                            </linearGradient>
                            <title >Valkokettu on Linkedin</title>
                            <path fill="black" d="M48,42c0,3.313-2.687,6-6,6H6c-3.313,0-6-2.687-6-6V6 c0-3.313,2.687-6,6-6h36c3.313,0,6,2.687,6,6V42z"
                            ></path>
                            <g >
                                <g >
                                <path fill="#FFFFFF" d="M15.731,11.633c-1.608,0-2.658,1.083-2.625,2.527c-0.033,1.378,1.018,2.494,2.593,2.494 c1.641,0,2.691-1.116,2.691-2.494C18.357,12.716,17.339,11.633,15.731,11.633z M13.237,35.557h4.988V18.508h-4.988V35.557z M31.712,18.748c-1.595,0-3.222-0.329-4.956,2.36h-0.099l-0.087-2.599h-4.417c0.065,1.411,0.074,3.518,0.074,5.52v11.529h4.988 v-9.854c0-0.46,0.065-0.919,0.196-1.248c0.328-0.919,1.149-1.871,2.527-1.871c1.805,0,2.527,1.411,2.527,3.479v9.494h4.988V25.439 C37.455,20.713,34.993,18.748,31.712,18.748z"
                                ></path>
                                </g>
                            </g>
                        </svg>
                    </a>}
                    <a className="iconeMidiaSocial" href="https://instagram.com/valkokettu_official" title="Instagram">
                        <svg className="iconeMidiaSocial" fill="black" xmlns="https://www.w3.org/2000/svg" 
                             viewBox="0 0 16 16" >
                             <title >Valkokettu on Instagram</title>
                        <path d="M8 0C5.827 0 5.555.01 4.702.048 3.85.088 3.27.222 2.76.42c-.526.204-.973.478-1.417.923-.445.444-.72.89-.923 1.417-.198.51-.333 1.09-.372 1.942C.008 5.555 0 5.827 0 8s.01 2.445.048 3.298c.04.852.174 1.433.372 1.942.204.526.478.973.923 1.417.444.445.89.72 1.417.923.51.198 1.09.333 1.942.372.853.04 1.125.048 3.298.048s2.445-.01 3.298-.048c.852-.04 1.433-.174 1.942-.372.526-.204.973-.478 1.417-.923.445-.444.72-.89.923-1.417.198-.51.333-1.09.372-1.942.04-.853.048-1.125.048-3.298s-.01-2.445-.048-3.298c-.04-.852-.174-1.433-.372-1.942-.204-.526-.478-.973-.923-1.417-.444-.445-.89-.72-1.417-.923-.51-.198-1.09-.333-1.942-.372C10.445.008 10.173 0 8 0zm0 1.44c2.136 0 2.39.01 3.233.048.78.036 1.203.166 1.485.276.374.145.64.318.92.598.28.28.453.546.598.92.11.282.24.705.276 1.485.038.844.047 1.097.047 3.233s-.01 2.39-.048 3.233c-.036.78-.166 1.203-.276 1.485-.145.374-.318.64-.598.92-.28.28-.546.453-.92.598-.282.11-.705.24-1.485.276-.844.038-1.097.047-3.233.047s-2.39-.01-3.233-.048c-.78-.036-1.203-.166-1.485-.276-.374-.145-.64-.318-.92-.598-.28-.28-.453-.546-.598-.92-.11-.282-.24-.705-.276-1.485C1.45 10.39 1.44 10.136 1.44 8s.01-2.39.048-3.233c.036-.78.166-1.203.276-1.485.145-.374.318-.64.598-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276C5.61 1.45 5.864 1.44 8 1.44zm0 2.452c-2.27 0-4.108 1.84-4.108 4.108 0 2.27 1.84 4.108 4.108 4.108 2.27 0 4.108-1.84 4.108-4.108 0-2.27-1.84-4.108-4.108-4.108zm0 6.775c-1.473 0-2.667-1.194-2.667-2.667 0-1.473 1.194-2.667 2.667-2.667 1.473 0 2.667 1.194 2.667 2.667 0 1.473-1.194 2.667-2.667 2.667zm5.23-6.937c0 .53-.43.96-.96.96s-.96-.43-.96-.96.43-.96.96-.96.96.43.96.96z"/></svg>
                    </a>
                    {<a href="https://www.facebook.com/valkokettu" title="Valkokettu on Facebook">
                        <svg className="iconeMidiaSocial" data-icon="facebook" viewBox="0 0 32 32" fill="black">
                            <title >Valkokettu on Facebook</title>
                            <path d="M8 12 L13 12 L13 8 C13 2 17 1 24 2 L24 7 C20 7 19 7 19 10 L19 12 L24 12 L23 18 L19 18 L19 30 L13 30 L13 18 L8 18 z"></path>
                        </svg>
                    </a>}

                    <div><p>Copyright © Valkokettu 2020</p></div>

                    

                </div>
            </div>
            
        </footer>
    );
}

export default footer;