import React from 'react';
import ReactPlayer from 'react-player';
import './Robotti.css';

const robotSampleVideo = (props) => {

    return (
        <div className="video">
            <div className="videoHeather">
                <h2>{props.nome}</h2>
                <p>{props.titulo}</p>
            </div>
            <div className="videoPlayer">
                <ReactPlayer
                    url = {props.videoUrl}
                    width = "80%"
                    height = "80%"
                    controls = {true}>
                </ReactPlayer>
            </div>   
        </div>
    )
}

export default robotSampleVideo;