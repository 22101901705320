import React, {createRef, useState, useEffect} from 'react';
import ReactGA from 'react-ga';
import {Switch, Route, Redirect, useLocation} from 'react-router-dom'; 


import Navigation from './Components/Navigation/Navigation';
import SideDrawer from './Components/SideDrawer/SideDrawer';
import Backdrop from './Components/Backdrop/Backdrop';
import CoreValues from './Components/CoreValues/CoreValues';
import {listService} from './Components/Services/ServicesData';
import Services from './Components/Services/Services';
import Leadership from './Components/Leadership/Leadership'; 
import Footer from './Components/Footer/Footer';
import Contact from './Components/Contact/Contact'; 
import Partnership from './Components/Partnership/Partnership';
import News from './Components/News/News'; 
import Robotti from './Components/Robotti/Robotti';

import Robozinho from './Components/Robozinho/Robozinho';


import { listOfArticles } from './Components/Blog/BlogData';

import './App.css';

const gaId = "UA-167098631-1"; /*identificador google */

const topoTela = createRef(); 
const NewsReverseList = listOfArticles.reverse(); 


function App() {
    const robo = useRobo(true);
    const rota = useRoute('home');
    const drawerOpen = useDrawer(); 
    
    
    let location = useLocation();
    
      
    let query = useQuery();

    
        
    ReactGA.initialize(gaId); 
    ReactGA.event({
      category: "Visit",
      action: "user call the site"
    });

    /* grava navegação no goggle analytics */
    useEffect(() => {
        let pagina = location.pathname
        ReactGA.set({ page: pagina });

        pagina = window.location.href 
        if (location.pathname === './service') {
            pagina = pagina + '/' + rota.itemOnPage;
        }

        ReactGA.pageview(pagina);
        
    }); 
    
    /* se trocou de pagina vai pro topo da tela */
    useEffect(() => { 
        topoTela.current.scrollIntoView('start', 'smooth'); 
    },[location.pathname]); 
    
    function sideDrawerClickHandler()  {
        drawerOpen.ToggleDrawer();
    }
   
   


  return (
    <div className="App">
        <Navigation drawerClickHandler={drawerOpen.ToggleDrawer}></Navigation>
        <SideDrawer show={drawerOpen.value}
                    menuOnClick={sideDrawerClickHandler} /> 

        {drawerOpen.value && <Backdrop click={drawerOpen.ToggleDrawer} /> }
        {!robo.roboFechado && <Backdrop click={robo.ToggleRobo} />}
        <main ref={topoTela}>
            <Switch>
                <Route exact path= '/'>
                    <Redirect to={{pathname:'/home'}}></Redirect>
                </Route>
                <Route exact path="/home">
                    <div>
                        <CoreValues ></CoreValues>
                        <Footer></Footer>          
                    </div>
                </Route>
                <Route exact path="/service">
                    <div>
                        <Services listaServicos = {listService}
                                readMoreClickHandler={rota.ChangeItem}
                                numero={rota.itemOnPage} ></Services>
                        <Footer></Footer>          
                    </div>
                </Route>
                <Route exact path="/leadership">
                    <div>
                        <Leadership></Leadership>
                        <Footer></Footer>
                    </div>
                </Route>
                <Route exact path="/partnership">
                    <div>
                        <Partnership></Partnership>
                        <Footer></Footer>
                    </div>
                </Route>
                <Route exact path="/robotti">
                    <div>
                        <Robotti></Robotti>
                        <Footer></Footer>
                    </div>
                </Route>
                <Route exact path="/news">
                    <div>
                        <News reverseList={NewsReverseList}
                              index = {query.get("id")}></News>
                        
                        <Footer></Footer>
                    </div>
                </Route>
                
                <Route exact path="/contact">
                    <div>
                        <Contact></Contact>
                    </div>
                </Route>
            </Switch>
            
        </main>
        <Robozinho roboFechado={robo.roboFechado}
                   onClick={robo.ToggleRobo}
                   ></Robozinho> 
    </div>    
  );

}

export default App;

function useDrawer() {
    const [drawerOpen, setDrawerOpen] = useState(false);
  
    function ToggleDrawer() {
       setDrawerOpen(!drawerOpen);
    } 
  
    return { value: drawerOpen,
             ToggleDrawer }
  
  };

function useRoute() {
    const [item, setItem] = useState(0);
  
    
    function ChangeItem(newItem) {
        setItem(newItem);
    };
  
    return { itemOnPage: item,
             ChangeItem 
           }
  
}  

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function useRobo() {
    const [roboFechado, setRoboFechado] = useState(true);

    function ToggleRobo() {
        
        setRoboFechado(!roboFechado);
    }

    return ({
        roboFechado,
        ToggleRobo
    })
}


   
   

