import React from 'react';
export const coreValue = [
    {
        language: 'ing',
        icon: 1,
        alt: 'trusted approach icon',
        title:'Trusted approach',
        description:<>Every business is unique. <strong>Innovative thinking, proven methodology and new technologies</strong> can partner with existing know how at the customer to deliver outstanding solutions and added value to the business. The goal is to deliver <strong>sustainable value</strong> as fast as possible with minimum impact to regular business operation.</>

    },
    {
        language: 'ing',
        icon: 2,
        alt: 'agile icon',
        title:'Agile and Digital',
        description:<>In an era of digital disruption, <strong>speed must be at the core of each project</strong>. Fast delivery means fast improvement and new ideas. To be “AGILE” means that everyday tasks must run simultaneously to the innovation we bring.</>

    },
    {
        language: 'ing',
        icon: 3,
        alt: 'from idea icon',
        title:'From Idea to Deployment',
        description:<>By transforming ideas into business value <strong>we can embed innovation and productivity</strong> at the core of any organization.</>

    },
    {
        language: 'ing',
        icon: 4,
        alt: 'tecnology icon',
        title:'Technology based ',
        description:<>Technology will never stop evolving. A trusted advisor will help companies steer the organization with useful new technologies. <strong>New technologies can energize legacy systems</strong> while lowering current costs.</>

    }
];

export const mainText = [
    {
        language: 'ing',
        subject: 'About us',
        title:'IMPROVING THE WORLD THROUGH TECHNOLOGY',
        subtitle: 'Thrusting our clients into the future',
        description: <><strong>Valkokettu</strong> is an <strong>Information Technology Consulting</strong> company located in Finland and Portugal. Our goal is to be the #1 trusted advisor where companies can find unbiased strategic insights about business, technology and solutions. Leveraging on its team's extensive business and IT experience, <strong>Valkokettu can help customers transform their business</strong> bringing real value to their operation. We have the experience, the training, the knowledge and the subject matter expertise to advice our customers.</>,
        

    }
]
