import React from 'react';
import Card from './Card';
import './ListCards.css';



const listCards = ({cardContents}) => {
    
    return (
    <div className="cardlist">
       
        {cardContents.map((cardContent,i) => {
            
            return (
                    <Card key = {i}
                          icon={cardContents[i].icon} 
                          alt ={cardContents[i].alt}
                          title = {cardContents[i].title}
                          description = {cardContents[i].description} 
                    />) 
            })}
             
    </div>
   );

}

export default listCards;