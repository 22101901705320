import React from 'react';
import './Robotti.css';
import { what, why, where, how } from './RobottiData'


const determinaVetor = (assunto) => {
    
    switch(assunto) {
        case 'what': 
            return what
        case 'why' :
            return why
        case 'where' :
            return where
        case 'how':
            return how
        default:
            return 'erro'
    }
}



const conteudo = (props) => {
    
    let vetor = determinaVetor(props.assunto)
    
    
    return (
        <div className="what">
            {vetor.filter(el => el.language === 'en').map((item, i) => {
                return (
                    <div key={item.id} className="whatParagraph">{item.message}</div>
                )
            })}    
        </div>
    )
}

export default conteudo