import React from 'react';
import './Email.css';
import {extensionActualDomain} from '../../utils';
import MessageBox from '../MessageBox/MessageBox';
import {urlServEmail} from '../../config';

/* DESENVOLVIMENTO */
/*const urlServer  = 'http://localhost:3002';*/

/* PRODUÇÂO */
/* const urlServer  = 'http://ec2-3-82-241-9.compute-1.amazonaws.com:3002'; */

let urlServer = urlServEmail();

const autoMessage = [
    {numero: 0,
     mensagem: <p>Thank you, we will get back to you soon!</p>,
    },
    
    {numero: 1, 
     mensagem: <p>Your message could not be sent, please use the contact informations bellow.</p>   
    }

]

class email extends React.Component {
    
    constructor() {
        super();
        this.state = {
            name: '',
            email: '',
            message: '',
            tipoMsgEnvio: 'nada',
            msgEnvio: '',
            sign:true
        }
    } 

    

    onNameChange = (event) => {
        this.setState({name: event.target.value,
                       tipoMsgEnvio: 'nada' });
    }    
    onEmailChange = (event) => {
        this.setState({email: event.target.value,
                       tipoMsgEnvio: 'nada'});
    }   
    onMessageChange = (event) => {
        this.setState({message: event.target.value,
                       tipoMsgEnvio: 'nada'});
    } 

    onSignChange = (event) => {
        let estado = this.state.sign;
        this.setState({sign: !estado});
    }

    onSubmit = () => {
        let erroValidacao = false;
        let msgErro;

        /*troca botão por msg de enviando ... */
        this.setState({tipoMsgEnvio: 'aviso',
                           msgEnvio: 'Sending...'}) ;
        
        fetch(urlServer, 
               { method: 'post',
                 headers: {'Content-Type': 'application/json'},
                 body: JSON.stringify({
                    subject: 'Contact from website',
                    name: this.state.name,
                    email: this.state.email, 
                    content: this.state.message,
                    dominio: extensionActualDomain('valkokettu.'),
                    newsletter: this.state.sign ? 'Yes' : 'No'
                 })
                }
            )
        .then((response) => {
                
                if (!response.ok) {
                    
                    switch(response.status) {
                    case 422 :  {
                           erroValidacao = true;
                           break;
                        }
                    default:
                        throw new Error('Erro no envio de email');
                    }
                }
                return response;
              })    
        .then(response => response.json())
        .then(envio => {
                 if (envio) {
                    
                    if ((envio.statusCode === 422) || (erroValidacao === true)) {
                        msgErro = <p>{envio.body}</p>;              
                        
                        this.setState({tipoMsgEnvio: 'erro',
                                        msgEnvio: msgErro})
                   }   

                   else { /* enviado com sucesso */
                      this.setState({tipoMsgEnvio:'aviso',
                                     msgEnvio: autoMessage[0].mensagem})   
                   }  
                }
            })
        .catch(err => {
            this.setState({tipoMsgEnvio: 'erro',
                           msgEnvio: autoMessage[1].mensagem})   
            
        });

        /* se quer assinar newsletter cadastra no banco 
        if (this.state.sign) {
            
        } */ 


    }
    
    

    render() {
        
        return (
            <section>
                <div className="email">
                   <fieldset id="sendEmailForm" className="sendEmailForm">
                       {/*} <legend className="legenda"></legend> */}
                       <div className="info">
                           <label className="formLabel" htmlFor="name">Name</label>
                           <input className="txtbox" type="text" name="name" id="name"
                                  onChange={this.onNameChange}></input>
                       </div>
                       <div className="info">
                           <label className="formLabel" htmlFor="emailAdress">Email address</label>
                           <input className="txtbox" type="email" name="emailAdress" id="emailAdress"
                                  onChange={this.onEmailChange}></input>
                       </div>
                       <div className="info">
                           <label className="formLabel" htmlFor="message">Message</label>
                           <textarea className="texto" rows="5" name="message" id="message"
                                     onChange={this.onMessageChange}></textarea>
                       </div>
                       <div className="info">
                            <input type="checkbox" name="signNews" id="signNews"  
                                  checked={this.state.sign} onChange={this.onSignChange}></input>
                            <label htmlFor="signNews">I want to be informed about Valkokettu's news</label>       
                       </div>

                      { this.state.tipoMsgEnvio === 'nada' ? 
                            <div className="btnEmail">
                                <button className="btnEnvia" type="submit"
                                        onClick={this.onSubmit}>Send</button>
                            </div>  
                        :
                       <MessageBox tipo={this.state.tipoMsgEnvio} 
                                   message={this.state.msgEnvio}></MessageBox> }
                   </fieldset>
                </div>
            </section>
           );
    }
    


}

export default email;