import React from 'react';
import BotaoServico from './BotaoServico';

const listaServico = ({cardContents, numero, readMoreClickHandler}) => {
    
    return (
        <div className="listaServico">
        
            {cardContents.map((cardContent,i) => {
                
                return (
                        <BotaoServico key = {i}
                            numreferencia={i}
                            icon={cardContents[i].icon} 
                            alt ={cardContents[i].alt}
                            title = {cardContents[i].title}
                            selected = {(numero === i) ? true : false}
                            description = {cardContents[i].description}
                            readMoreClickHandler = {readMoreClickHandler}
                        />) 
                })}
                
        </div>
   );

}

export default listaServico;