import React from 'react';
import MenuFiller from '../ImageBar/MenuFiller';
import ImageBar from '../ImageBar/ImageBar';
import Email from './Email';

import './Contact.css'; 

const contato = props => {
    return (
        <div className="contact" > 
            <MenuFiller></MenuFiller>
            <ImageBar tamanho='small' title="CONTACT US" subtitle="we will get in touch shortly" ></ImageBar>
            <Email></Email>
            <div className="country">
                <article >
                    
                        <h1>Finland</h1>
                        <div className="name" >
                            <h4>Fabio Glaser</h4>
                            <p>+358 40 482 2033</p>
                            <p><a href="mailto:fabioglaser@valkokettu.fi">fabioglaser@valkokettu.fi</a></p>
                            <a href="https://www.linkedin.com/in/glaser" className="linkedin">
                                <svg className="iconeMidiaSocial" x="0px" y="0px" viewBox="0 0 48 48" >
                                    <linearGradient gradientUnits="userSpaceOnUse" x1="23.9995"
                                        y1="0" x2="23.9995" y2="48.0005" >
                                        <stop offset="0" ></stop>
                                        <stop offset="1" ></stop>
                                    </linearGradient>
                                    <path fill="black" d="M48,42c0,3.313-2.687,6-6,6H6c-3.313,0-6-2.687-6-6V6 c0-3.313,2.687-6,6-6h36c3.313,0,6,2.687,6,6V42z"
                                    ></path>
                                    <g >
                                        <g >
                                        <path fill="#FFFFFF" d="M15.731,11.633c-1.608,0-2.658,1.083-2.625,2.527c-0.033,1.378,1.018,2.494,2.593,2.494 c1.641,0,2.691-1.116,2.691-2.494C18.357,12.716,17.339,11.633,15.731,11.633z M13.237,35.557h4.988V18.508h-4.988V35.557z M31.712,18.748c-1.595,0-3.222-0.329-4.956,2.36h-0.099l-0.087-2.599h-4.417c0.065,1.411,0.074,3.518,0.074,5.52v11.529h4.988 v-9.854c0-0.46,0.065-0.919,0.196-1.248c0.328-0.919,1.149-1.871,2.527-1.871c1.805,0,2.527,1.411,2.527,3.479v9.494h4.988V25.439 C37.455,20.713,34.993,18.748,31.712,18.748z"
                                        ></path>
                                        </g>
                                    </g>
                                </svg>
                            </a>
                        </div>
                        <div className="name">
                            <h4>Regina Glaser</h4>
                            <p>+358 40 354 7553</p>
                            <p><a href="mailto:reginaglaser@valkokettu.fi">reginaglaser@valkokettu.fi</a></p>
                            <a href="https://www.linkedin.com/in/reginaglaser" className="linkedin">
                                <svg className="iconeMidiaSocial" x="0px" y="0px" viewBox="0 0 48 48" >
                                    <linearGradient gradientUnits="userSpaceOnUse" x1="23.9995"
                                        y1="0" x2="23.9995" y2="48.0005" >
                                        <stop offset="0" ></stop>
                                        <stop offset="1" ></stop>
                                    </linearGradient>
                                    <path fill="black" d="M48,42c0,3.313-2.687,6-6,6H6c-3.313,0-6-2.687-6-6V6 c0-3.313,2.687-6,6-6h36c3.313,0,6,2.687,6,6V42z"
                                    ></path>
                                    <g >
                                        <g >
                                        <path fill="#FFFFFF" d="M15.731,11.633c-1.608,0-2.658,1.083-2.625,2.527c-0.033,1.378,1.018,2.494,2.593,2.494 c1.641,0,2.691-1.116,2.691-2.494C18.357,12.716,17.339,11.633,15.731,11.633z M13.237,35.557h4.988V18.508h-4.988V35.557z M31.712,18.748c-1.595,0-3.222-0.329-4.956,2.36h-0.099l-0.087-2.599h-4.417c0.065,1.411,0.074,3.518,0.074,5.52v11.529h4.988 v-9.854c0-0.46,0.065-0.919,0.196-1.248c0.328-0.919,1.149-1.871,2.527-1.871c1.805,0,2.527,1.411,2.527,3.479v9.494h4.988V25.439 C37.455,20.713,34.993,18.748,31.712,18.748z"
                                        ></path>
                                        </g>
                                    </g>
                                </svg>
                            </a>
                        </div>
                       
                </article>

                <article >
                    
                        <h1>Portugal</h1>
                        <div className="name">
                            <h4>António Ribeiro da Cunha</h4>
                            <p>+351 93 326 5762</p>
                            <p><a href="mailto:fabioglaser@valkokettu.fi">antoniocunha@valkokettu.fi</a></p>
                            <a href="https://www.linkedin.com/in/antónio-ribeiro-da-cunha-1b57832" className="linkedin">
                                    <svg className="iconeMidiaSocial" x="0px" y="0px" viewBox="0 0 48 48" >
                                        <linearGradient gradientUnits="userSpaceOnUse" x1="23.9995"
                                            y1="0" x2="23.9995" y2="48.0005" >
                                            <stop offset="0" ></stop>
                                            <stop offset="1" ></stop>
                                        </linearGradient>
                                        <path fill="black" d="M48,42c0,3.313-2.687,6-6,6H6c-3.313,0-6-2.687-6-6V6 c0-3.313,2.687-6,6-6h36c3.313,0,6,2.687,6,6V42z"
                                        ></path>
                                        <g >
                                            <g >
                                            <path fill="#FFFFFF" d="M15.731,11.633c-1.608,0-2.658,1.083-2.625,2.527c-0.033,1.378,1.018,2.494,2.593,2.494 c1.641,0,2.691-1.116,2.691-2.494C18.357,12.716,17.339,11.633,15.731,11.633z M13.237,35.557h4.988V18.508h-4.988V35.557z M31.712,18.748c-1.595,0-3.222-0.329-4.956,2.36h-0.099l-0.087-2.599h-4.417c0.065,1.411,0.074,3.518,0.074,5.52v11.529h4.988 v-9.854c0-0.46,0.065-0.919,0.196-1.248c0.328-0.919,1.149-1.871,2.527-1.871c1.805,0,2.527,1.411,2.527,3.479v9.494h4.988V25.439 C37.455,20.713,34.993,18.748,31.712,18.748z"
                                            ></path>
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                        </div>
                   
                </article>
            </div>
                
        </div>
            
       
    );
}

export default contato;