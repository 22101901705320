import React from 'react';
import './BotaoServico.css';
import {icons} from '../../Icons';

const BotaoServico = ({numreferencia, icon, alt, title, selected, readMoreClickHandler}) => {
        
    let icone = icons[String(icon)-1];
    let nomeClasse = 'botaoServico';

    if (selected) {
        nomeClasse = 'botaoServico botaoServicoSelected';
    }


    return(
        <article className={nomeClasse}>
            <div className="conteudoBotao" onClick={() => readMoreClickHandler(numreferencia )} >
                <img src={icone} alt={alt}></img>
                <p className="titulobotao">{title}</p> 
            </div>
       </article>
    )
}

export default BotaoServico;