import React from 'react';
import MenuFiller from '../ImageBar/MenuFiller';
import ImageBar from '../ImageBar/ImageBar';
import './Partnership.css';
import {partner, mainText} from './Partners.js';

import logoObjective from './logoObjective200.png';
import logoScopphu  from './logoScopphu200.png';
import logoD5 from './logod5.svg';


const partnership = () => {
    let title = mainText[0].title;
    let subtitle = mainText[0].subtitle;
    let txtAbout = mainText[0].description;
    let subject = mainText[0].subject;   
    return (
       <section>
            <MenuFiller></MenuFiller>
            <ImageBar tamanho="small"
                      title={title} 
                      subtitle={subtitle} ></ImageBar>
            <div className="descricaoPrincipal">
                <h1>{subject}</h1>
                <p>{txtAbout}</p>
            </div>          
            <div className="partners">
                <article className="partner">
                    <img src={logoObjective} alt={partner[0].partner}></img>  
                    <p>{partner[0].description}</p>
                    <a href={partner[0].link}>{partner[0].site}</a> 
                </article>
                <article className="partner">
                    <img src={logoScopphu} alt={partner[1].partner}></img>  
                    <p>{partner[1].description}</p>
                    <a href={partner[1].link}>{partner[1].site}</a> 
                </article>
                <article className="partner">
                    <img src={logoD5} alt={partner[2].partner} style={{width: 200}}></img>  
                    <p>{partner[2].description}</p>
                    <a href={partner[2].link}>{partner[2].site}</a> 
                </article>
            </div>          
       </section>
   );
}

export default partnership;