import React from 'react';
import './Card.css';
import {icons} from '../../Icons';

const card = ({icon, alt, title, description}) => {
        
    let icone = icons[String(icon)-1];
    
    return(
        <article className="card">
            <img src={icone} alt={alt}></img>
            <h2>{title}</h2>
            <p>{description}</p>
        </article>
    )
}

export default card;