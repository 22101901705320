import React from 'react';
import MenuFiller from '../ImageBar/MenuFiller';
import ImageBar from '../ImageBar/ImageBar';
import Quadro from './Quadro';
import { mainText } from './RobottiData';
import ListOfVideos from './ListOfVideos';

import "./Robotti.css";

const services = props => {
    let title = mainText[0].title;
    let subtitle = mainText[0].subtitle;
    let subject = mainText[0].subject;
    let txtAbout = mainText[0].description;
    
    return (
        <section >
            <MenuFiller></MenuFiller>
            <ImageBar tamanho='small'
                     title={title}
                     subtitle={subtitle}></ImageBar>
            <div className="descricaoPrincipal">
                <h1>{subject}</h1>
                <p>{txtAbout}</p>
            </div>         
            
            <div className="textoRobotti">
                <div className="assunto">
                    <Quadro question="what"></Quadro>
                    <Quadro question="why"></Quadro>
                    <Quadro question="where"></Quadro>
                    <Quadro question="how"></Quadro>
                </div>
                
            </div>
            <div>
                <ListOfVideos></ListOfVideos>
            </div>
                   
            
                                           
        </section>
        
       
    );
}

export default services; 