
/* const ambiente = () => ('DESENV'); */
/* const ambiente = () => ('HOMOL'); */
 const ambiente = () => ('PROD'); 


export const urlServEmail = () => {
    let url = '';

    switch(ambiente()) {
        case 'DESENV':
            /*url = 'http://localhost:3002'; */
            url = 'https://8qoq22qeya.execute-api.us-east-1.amazonaws.com/beta';
            break;
        case 'PROD':
            /*url = 'https://ssl.valkokettu.com:3002/emailContato';*/
            url = 'https://8qoq22qeya.execute-api.us-east-1.amazonaws.com/beta'
            break;
        default:
            url = 'INDEFINIDO';    
    }

    return url;
}

