import React from 'react';
import photoFG from './fabioglaser.jpg';
import photoRG from './reginaglaser.jpg';
import photoAC from './antoniocunha.jpg';

export const photo = [photoFG, photoRG, photoAC];

export const leader = [
    {
        language: 'ing',
        foto: 1,
        alt: 'foto FG',
        title:'Fabio Luis Glaser',
        geografia:'Finland',
        cargo: 'Co-founder and CEO',
        description: `Over 25 years of IT leadership experience with a consistent track record of developing and executing IT strategies in alignment with corporate objectives. Managed organizations of up to 400 people and annual budgets over USD 500MM. Proven track record of complex and large system development and project implementation and creating cohesive teams for multi million dollars strategic initiatives. Was Senior IT Manager for Telecom companies in Brazil and Senior Manager at Accenture and Ernst & Young.`
    },
    {
        language: 'ing',
        foto: 2,
        alt: 'foto RG',
        title:'Regina Glaser',
        geografia: 'Finland',
        cargo: 'Co-founder and CTO',
        description: `Over 20 years of IT experience in system design, data modeling, and project management in several different industries ranging from retail to international trading and infrastructure. Expert in Data Management, System Development and Process Management. Relevant experience includes several IT projects for Consulting companies and many years as partner of an IT consulting company in Brazil.`
    },
    {
        language: 'ing',
        foto: 3,
        alt: 'foto AC',
        title:'Antonio Ribeiro da Cunha',
        geografia: 'Portugal',
        cargo: 'Co-founder',
        description: `Experienced professional has led many international projects of process management to develop new business and promote strategic turnarounds in many different industries from infrastructure to services and goods. Has dealt both with private and government companies. Previously worked as Adviser for KPMG in Portugal and acted as Executive Member in FGV (major project foundation in Brazil) responsible for the Project Office.`
    }];

export const mainText = [
        {
            language: 'ing',
            subject: 'Leadership',
            title:'WHOEVER WALKS WITH THE WISE WILL BECOME WISE, BUT THE COMPANION OF FOOLS SUFFERS HARM.',
            subtitle: 'Solomon (990-931 BC)',
            description: <><strong>Valkokettu</strong> has three partners with extensive experience both in business and technology having led projects in many different industries. </>
        }
    ]    