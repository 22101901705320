import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../Logo/Logo';
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';
import './Navigation.css';
import {itensMenu} from './NavigationData';

const Navigation = props => {
   
    
    return (
            <header className="toolbar" >
               <nav className= "toolbar_navigation">
                  <div className= "toggle_button"><DrawerToggleButton click={props.drawerClickHandler}></DrawerToggleButton></div>
                  <div className= "toolbar_logo"><Logo></Logo></div>
                  <div className="spacer"></div>
                  <div className= "toolbar_navigation_items">
                    <ul>
                        {itensMenu.map((menuItem, i) =>  {
                          return (
                                  <li key={i} >
                                      <Link to={menuItem.route}>{menuItem.item}</Link>
                                  </li>)
                        })}
                    </ul>
                  </div> 
               </nav>

           </header>
        )
}

    
export default Navigation