import React from 'react';
import './DescricaoServico.css';
import {icons} from '../../Icons';


const descricaoServico = ({cardContents, numero}) => {
    let cardContent = cardContents[numero];     
    let icone = icons[String(cardContent.icon)-1];
       
    return(
        
            <article >
                <div className="descricaoServico">
                    <div className="iconeServico">
                        <img src={icone} alt={cardContent.alt}></img>
                    </div>
                    <div className="textoServico" >
                        <h1>{cardContent.title}</h1>
                        <p>{cardContent.description}</p>
                        <h4>Objectives</h4>
                        <p>{cardContent.objective}</p>
                        <h4>Benefits</h4>
                        <p>{cardContent.benefits}</p>
                    </div>
                </div>
            </article>
        
    )
}

export default descricaoServico;