import React , {createRef } from "react";


export const listService = [
    {
        language: 'ing',
        icon:5,
        alt: 'IT Strategy icon',
        title:'IT Strategy',
        description: <>The lack of a robust IT strategy causes businesses to be inefficient and slow to react to different business needs. Regardless of size, the long-term success of a business depends on its IT support systems and infrastructure.<strong>What part of your IT can be outsourced? How much value is your IT delivering to your business? How well your IT systems can react to change?</strong></>,
        objective: <>Design or review the overall IT Strategy and Governance addressing aspects such as Architecture, Integration, Processes, Budgeting, Sourcing, and Systems Operation.</>,
        benefits: <>Valkokettu can help customers improve their IT service lowering cost and improving stability while building a new IT platform. At the end of the project, the customer will have a clear plan of action to create an information technology capability that delivers sustainable added value for their organization.</>,
        referencia: createRef()
    },
    {
        language: 'ing',
        icon:6,
        alt: 'Data Science icon',
        title:'Data Engineering',
        description: <>Data engineering is the aspect of data science that focuses on <strong>practical applications of data collection and analysis</strong>. It is a multi-disciplinary field that uses scientific methods, processes, algorithms and system to extract knowledge from data. Does your organization have data and doesn’t extract insights from them? We can help you.</>,
        objective:<>On a Data Engineering project, we can help your company from Strategy to Operation. Leveraging on our expertise on the field, we can help your company set a sound Data Strategy to guarantee maximum return of investment. From data gathering, modeling, cleaning, validating all the way to effectively training Machine Learning algorithms, the Data Cycle will allow the company to <strong>extract insightful and meaningful information</strong> from its data lakes. Also, any Data Process has to be refueled by its previous insights or the model will become obsolete.</>,
        benefits:<>A successful Data Engineering project will empower the executives to make better decisions, steer actions based on trends, identify opportunities, test decisions and help an organization to focus on what is important. As long as Data is used well, a <strong>Data Engineering project will add value to any type of business</strong>.</>,
        referencia: createRef()
    },
    {
        language: 'ing',
        icon:7,
        alt: 'Business Assessment icon',
        title:'Business Assessment',
        description: <>The success of every company is closely related to its business processes and its continuous improvement approach. <strong>How well are your processes documented? Does every employee understand how his work affects other areas of the company? What kind of measures are in place to make sure work flows as efficiently as possible?</strong></>,
        objective:<>Through a systemized and procedural analysis of a product, line of business, process and/or system, we can identify opportunities to accelerate and streamline business processes and results.</>,
        benefits:<>By creating and reporting key performance indicators (KPIs), the company will be able to accurately measure and improve its current reality, opportunities, and gaps.</>,
        referencia: createRef()
    },
    {
        language: 'ing',
        icon:8,
        alt: 'Project Management icon',
        title:'Project Management',
        description: <>Strategic projects need strategic management.<strong>How well are your projects staffed? Are you antecipating risks? Do you have clear change management procedures?</strong> This and other questions can only be answered with good project management practices.</>,
        objective:<>The primary goal of Project Management is to achieve all of the projects' objectives within the given constraints of time,budget and resources. Valkokettu can help you achieve every  goal of an endeavour within the given constraints.</>,
        benefits:<>Based on world-established best practices methodologies, customers will be able to track their strategic projects with guaranteed quality delivery. A miss-guided project is sure to bring loss and damage to any customer. Valkokettu's team will help customers  achieve the project scope on time and on budget.</>,
        referencia: createRef()
    },
    {
        language: 'ing',
        icon:9,
        alt: 'Go to Cloud icon',
        title:'Go to Cloud ',
        description: <>The percentage of companies investing in Cloud Computing is growing every year. In general, the benefits of moving to the cloud rapidly pays for most of the projects. But unless this movement is based on a clear strategy and done with the necessary cautions the outcome might be different than expected. <strong>What are the drivers of the change? What is your digital strategy? What is your cloud provider approach?</strong></>,
        objective:<>Design a strategy for migrating old systems to the cloud in order to increase stability while lowering costs. With our experience on large scale IT infrastructures and projects,  we will evaluate your decision drivers, your infrastrucure readiness, what to move to the cloud and where it can be the most beneficial. In addition, the migration strategy will only be fault proof through a detailed assessment of the busniess and its infrastructure.</>,
        benefits:<>It does not matter how good a certain system is. As time goes by, its infrastructure will become obsolete and its operational cost will raise. Instead of upgrading or buying a whole new infrastructure, we can guarantee stability at a lower cost by carefully planning and executing a migration to a cloud-computing infrastructure.</>,
        referencia: createRef()
    },
    {
        language: 'ing',
        icon:10,
        alt: 'System Development icon',
        title:'System Development ',
        description: <>A system development project covers the whole process of requirements gathering, designing, testing, and implementing new software. Includes, but is not limited to, the development of customized systems, the creation of databases, and/or the acquisition of third party developed software. <strong>Do you have well documented business processes? What are the best technologies available? How does this new system integrates with the enterprise system architeture?</strong></>,
        objective:<>A system development project requires years of experience to master. It is important to address capabilities such as change management, testing, infrastructure sizing, scope management, readiness check and data migration. The new system must add sustainable value to the business.</>,
        benefits:<>By carefully assessing business requirements and constraints, we can design and develop custom systems that will allow our customers to focus on their core business, growing revenue and customer base. Our focus is always on the most valuabe requirements allowing the customer to capture early benefits from the project.</>,
        referencia: createRef()
    },
    /*{
        language: 'ing',
        icon:10,
        alt: 'Gamification icon',
        title:'Gamification ',
        description: <>Gamification is a process for integrating game mechanics into one or more processes that already exist to motivate participation, increase engagement, and built loyalty. Leveraging on a Design Thinking methodology, Gamification concepts uses competition, points, achievement, rules of play, status and self-expression to create a framework where the customer can radically upgrade business results.</>,
        objective:<>This type of project can be applied for both employees and customers. Valkokettu can drive the project to align business objectives to the goals of the players. We will bring innivation to your core business!</>,
        benefits:<>A Gamification project encourage actions through positive feedback  to increase employee engagement, retain talents, improve the learning process, implement a meritocracy environment and boost business results.</>,
        referencia: createRef()
    },*/
    {
        language: 'ing',
        icon:11,
        alt: 'IFAM icon',
        title:'IFAM ',
        description: <>Integrating the main stages of a funding application and capitalization process with the best practices of Project Management we can deliver value with minimal expenditure! <strong>There are major opportunities in the market. Are you ready to harvest them?</strong></>,
        objective:<>We developed IFAM - Innovation Funding Application Methodology based on our extended experience in designing and applying for external funding programs for Innovation projects. The methodology integrates the main stages of a funding application and capitalization process with the best practices of Project Management. We have a specific knowledge to match the funding to the innovation process. By monitoring and reporting, we ensure that the fund transfer is in line with the project plan and implementation.</>,
        benefits:<>Through this kind of project, we  offer our customers great solutions for innovation products or services and the corresponding funding required. As long as the customer has the idea, we can develop the product and obtain appropriate funding.</>,
        referencia: createRef()
    },
    {
        language: 'ing',
        icon:12,
        alt: 'Readiness Assessment icon',
        title:'Readiness Assessment ',
        description: <><strong>Are you ready to grow your business or go internationally? Can you react fast to changing business environments and needs?</strong> By applying our methodology for Readiness Assessment, we will help you find bottlenecks or major constrains in your organization that might hinder your objectives.</>,
        objective:<>By thoroughly reviewing aspects of systems, security, infrastructure, scalability, backup and disaster recovery of a customer’s IT landscape, Valkokettu will provide a true and accurate picture of the customer’s IT readiness to support the growth of your business or an internationalization strategy.</>,
        benefits:<>At the end of the project, a high-level roadmap of actions will be laid out for the customer, prioritized in terms of added value and relevance for the business.</>,
        referencia: createRef()
    },
    {
        language: 'ing',
        icon:13,
        alt: 'Business Continuity icon',
        title:'Business Continuity ',
        description: <>Business continuity is an organization’s ability to ensure operations and core business functions are not severely impacted by a disaster or unplanned incident that would impact business processes or critical systems. <strong>Are you ready to respond to major disruptive events?</strong></>,
        objective:<>By addressing all key aspects of the customer’s operation, Valkokettu can propose practices and processes to minimize external incidents' effects and even turn those incidents in business opportunities.</>,
        benefits:<>Companies that can react fast to unplanned incidents will be able to grow its revenue and value during difficult times when the competition may be struggling.</>,
        referencia: createRef()
    } 
];

export const mainText = [
    {
        language: 'ing',
        subject: 'Services',
        title:'EXPECT THE BEST, PLAN FOR THE WORST, AND ...',
        subtitle: 'Prepare to be surprised',
        description: <>We want to solve real business issues. <strong>Every project must have a solid business case</strong> with clear expected results to guarantee stakeholders support and timely decision-making. Objectives, budget, timeframe and responsibilities have to be set before the project starts to minimize risks and provide a clear path for development and deployment of the project. <strong>We provide tailored solutions</strong> and as such, it is only logical that we have customized projects that can address any business issue through methodology and technology. We have developed a list of sample projects to showcase our capabilities. </>
    }
]